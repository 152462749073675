import { MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, FormikProps } from "formik";
import { Select, TextField } from "formik-mui";
import SubmitCancel from "../../../components/Forms/SubmitCancel";
import { Polarization, TenantSat } from "../../../openapi";
import { Cpe } from "../../../openapi/models/Cpe";

export type CpeForForm = Omit<
  Cpe,
  "tenant" | "id" | "tenantId" | "satName" | "lastReport"
>;

export interface FormValues {
  name: string;
  satInfoId: string;
  polarization: Polarization;
  serial: string;
}

export interface FormProps {
  cpe: CpeForForm;
  satInfos: TenantSat[];
}

export default function ExtForm({
  touched,
  errors,
  isSubmitting,
  submitForm,
  satInfos,
}: FormProps & FormikProps<FormValues>) {
  return (
    <Form>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Field
          autoFocus
          component={TextField}
          name="name"
          label="Nombre"
          variant="standard"
        />
        <Field
          component={TextField}
          name="serial"
          label="Número de serie"
          variant="standard"
        />
        <Field component={Select} name="satInfoId" label="Satelite">
          {satInfos.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.satelliteInformation.name}
            </MenuItem>
          ))}
        </Field>
        <Field component={Select} name="polarization" label="Polarización">
          {(Object.keys(Polarization) as Array<keyof typeof Polarization>).map(
            (s, idx) => (
              <MenuItem key={Polarization[s]} value={idx}>
                {s}
              </MenuItem>
            )
          )}
        </Field>
      </Box>
      <SubmitCancel isSubmitting={isSubmitting} />
    </Form>
  );
}

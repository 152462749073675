import { Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { AppDrawer } from "./Drawer";
import { AppBar } from "./TopBar";

const mdTheme = createTheme();

type Props = {
  children: JSX.Element;
};

export function Layout({ children }: Props) {
  const [open, setOpen] = useState<boolean>(() => {
    return true;
  });

  const toggleDrawer = () => {
    console.log("Setting open to ", !open);
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          toggleDrawer={toggleDrawer}
        ></AppBar>
        <AppDrawer toggleDrawer={toggleDrawer} open={open} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container
            maxWidth="lg"
            sx={{ mt: 4, mb: 4, backgroundColor: "#fff", flexGrow: 1 }}            
          >
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

import { SatelliteAlt, SettingsInputAntenna } from "@mui/icons-material";
import BussinesIcon from "@mui/icons-material/Business";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Divider,
  IconButton,
  List, ListSubheader,
  styled
} from "@mui/material";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import NavButton from "../Nav/NavButton";
import { drawerWidth } from "./Constants";
import TenantMenu from "./TenantMenu";

interface Props extends DrawerProps {
  toggleDrawer?: () => void;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const AppDrawer = (props: Props) => {
  return (
    <Drawer variant="permanent" open={props.open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={props.toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />      
      <List component="nav">
        <NavButton to="/" primary="Dashboard" icon={<DashboardIcon />} />
        <Divider sx={{ my: 1 }} />
        <TenantMenu />
        <NavButton to="/app/sats" primary="Satelites" icon={<SatelliteAlt />} />
        <NavButton
          to="/app/cpes"
          primary="Antenas"
          icon={<SettingsInputAntenna />}
        />
        <Divider sx={{ my: 1 }} />
        <ListSubheader>Administración</ListSubheader>
        <NavButton
          to="/admin/orgs"
          primary="Organizaciones"
          icon={<BussinesIcon />}
        />
        <NavButton
          to="/admin/sats"
          primary="Satelites"
          icon={<SatelliteAlt />}
        />
      </List>
    </Drawer>
  );
};

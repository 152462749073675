/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Polarization {
    Vertical = 'VERTICAL',
    Horizontal = 'HORIZONTAL',
    Circular = 'CIRCULAR'
}

export function PolarizationFromJSON(json: any): Polarization {
    return PolarizationFromJSONTyped(json, false);
}

export function PolarizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Polarization {
    return json as Polarization;
}

export function PolarizationToJSON(value?: Polarization | null): any {
    return value as any;
}


import MenuIcon from "@mui/icons-material/Menu";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import { useEffect } from "react";
import { useAppState } from "../../providers/StateProvider";
import { drawerWidth } from "./Constants";
import TenantMenu from "./TenantMenu";
import { UserDropdown } from "./UserDropdown";

interface Props extends AppBarProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

export const TopBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "toggleDrawer",
})<Props>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({ open, toggleDrawer }: Props) => {
  const state = useAppState();
  useEffect(() => {
    console.log("Open?", open);
  }, [open]);

  return (
    <TopBar open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Panel de Control
        </Typography>        
        <UserDropdown />
      </Toolbar>
    </TopBar>
  );
};

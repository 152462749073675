/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Polarization,
    PolarizationFromJSON,
    PolarizationFromJSONTyped,
    PolarizationToJSON,
} from './Polarization';

/**
 * 
 * @export
 * @interface CreateCpeDto
 */
export interface CreateCpeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCpeDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCpeDto
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCpeDto
     */
    tenantSatId: string;
    /**
     * 
     * @type {Polarization}
     * @memberof CreateCpeDto
     */
    polarization: Polarization;
    /**
     * 
     * @type {string}
     * @memberof CreateCpeDto
     */
    serial: string;
}

export function CreateCpeDtoFromJSON(json: any): CreateCpeDto {
    return CreateCpeDtoFromJSONTyped(json, false);
}

export function CreateCpeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCpeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'tenantId': json['tenantId'],
        'tenantSatId': json['tenantSatId'],
        'polarization': PolarizationFromJSON(json['polarization']),
        'serial': json['serial'],
    };
}

export function CreateCpeDtoToJSON(value?: CreateCpeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tenantId': value.tenantId,
        'tenantSatId': value.tenantSatId,
        'polarization': PolarizationToJSON(value.polarization),
        'serial': value.serial,
    };
}


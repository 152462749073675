import React, { useEffect, useState } from "react";
import {
  Configuration,
  CpeApi,
  SatelliteInformationApi,
  TenantApi,
} from "../openapi";
import { useAuth } from "./AuthProvider";
import { createCtx } from "./utils";

export type ApiContextType = {
  tenants: TenantApi;
  satellites: SatelliteInformationApi;
  cpes: CpeApi;
};

type Props = {
  children: React.ReactNode;
};

const [useApi, CtxProvider] = createCtx<ApiContextType>();

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://cc.bellagamba.com.ar/api";

const ApiProvider = ({ children }: Props) => {
  const auth = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conf, setConf] = useState(
    new Configuration({
      basePath: url,
      fetchApi: auth.fetch,
    })
  );
  const [tenants, setTenants] = useState(new TenantApi(conf));
  const [satellites, setSattelites] = useState(
    new SatelliteInformationApi(conf)
  );
  const [cpes, setCpes] = useState(new CpeApi(conf));

  /*
  useEffect(() => {
    let opts: ConfigurationParameters = { ...defaultOpts };
    console.log("AUTH token updated", auth.token);

    if (auth.token) {
      opts.accessToken = auth.token;
    }
    const conf = new Configuration(opts);
    setConf(conf);
    console.log("updated conf");
    // setTenants(new TenantApi(conf));
    // setSattelites(new SatelliteInformationApi(conf));

    setLoaded(true);
  }, [auth, auth.token]);
*/
  useEffect(() => {
    console.log("re-creating apis");
    setSattelites(new SatelliteInformationApi(conf));
    setTenants(new TenantApi(conf));
    setCpes(new CpeApi(conf));
  }, [conf]);

  return (
    <CtxProvider
      value={{
        tenants,
        satellites,
        cpes,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export { useApi, ApiProvider };

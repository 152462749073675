import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SatelliteInformation } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import { useAppState } from "../../../providers/StateProvider";
import { SatelliteSelectForm, Values } from "./SatelliteForm";

export default function SatelliteNew() {
  const api = useApi();
  const state = useAppState();
  const navigate = useNavigate();
  const [sats, setSats] = useState<SatelliteInformation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const SelForm = withFormik<{ options: SatelliteInformation[] }, Values>({
    handleSubmit: async (values, actions) => {
      try {
        await api.tenants.tenantControllerAddSatellite({
          id: state.selectedTenant!.id,
          satelliteInformationId: values.selected.id,
        });
        navigate(-1);
      } catch {
        alert("No se pudo crear el registro");
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(SatelliteSelectForm);

  useEffect(() => {
    if (state.selectedTenant) {
      const all = api.satellites.satelliteInformationControllerFindAll();
      // .then((v) => setSats(v));
      const sel = api.tenants.tenantControllerGetSatellites({
        id: state.selectedTenant.id,
      });
      // .then((sats) => setSelected(sats));
      Promise.all([all, sel]).then(([all, sel]) => {
        setSats(all);
        setLoading(false);
      });
    }
  }, [api, state.selectedTenant]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SelForm options={sats} />;
}

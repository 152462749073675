import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Polarization, TenantSat } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import { useAppState } from "../../../providers/StateProvider";
import Form, { FormProps, FormValues } from "./CpeForm";

export default function CpeNew() {
  const api = useApi();
  const navigate = useNavigate();
  const state = useAppState();
  const [satellites, setSatellites] = useState<TenantSat[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const NewForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        name: props.cpe.name,
        satInfoId: props.cpe.tenantSatId,
        polarization: props.cpe.polarization,
        serial: props.cpe.serial,
      };
    },

    handleSubmit: async (values, actions) => {
      console.log(values);

      try {
        const res = await api.cpes.cpeControllerCreate({
          createCpeDto: {
            name: values.name,
            serial: values.serial,
            tenantSatId: values.satInfoId,
            tenantId: state.selectedTenant!.id,
            polarization: values.polarization,
          },
        });
        console.log("RES", res);

        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(Form);

  useEffect(() => {
    if (state.selectedTenant) {
      const sats = api.tenants.tenantControllerGetSatellites({
        id: state.selectedTenant?.id,
      });

      Promise.all([sats])
        .then(([sats]) => {
          setSatellites(sats);
        })
        .finally(() => setLoading(false));
    }
  }, [api, state.selectedTenant]);

  if (loading) return <div>Loading</div>;

  return (
    <NewForm
      cpe={{
        name: "",
        tenantSatId: "",
        serial: "",
        polarization: Polarization.Horizontal,
      }}
      satInfos={satellites}
    />
  );
}

import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cpe, TenantSat } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import { useAppState } from "../../../providers/StateProvider";
import Form, { FormProps, FormValues } from "./CpeForm";

export default function CpeEdit() {
  const params = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const state = useAppState();
  const [item, setItem] = useState<Cpe>();
  const [satellites, setSatellites] = useState<TenantSat[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const NewForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        name: props.cpe.name,
        satInfoId: props.cpe.tenantSatId,
        polarization: props.cpe.polarization,
        serial: props.cpe.serial,
      };
    },

    handleSubmit: async (values, actions) => {
      console.log(values);

      try {
        const res = await api.cpes.cpeControllerUpdate({
          id: params.id!,
          updateCpeDto: {
            name: values.name,
            tenantSatId: values.satInfoId,
            tenantId: state.selectedTenant!.id,
            polarization: values.polarization,
            serial: values.serial,
          },
        });
        console.log("RES", res);

        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(Form);

  useEffect(() => {
    if (params.id) {
      api.cpes
        .cpeControllerFindOne({
          id: params.id,
        })
        .then((t) => {
          setItem(t);
        })
        .catch((r) => {
          setError(r.status);
        });
    }
  }, [params.id, api]);

  useEffect(() => {
    if (state.selectedTenant) {
      const sats = api.tenants.tenantControllerGetSatellites({
        id: state.selectedTenant?.id,
      });

      Promise.all([sats])
        .then(([sats]) => {
          setSatellites(sats);
        })
        .finally(() => setLoading(false));
    }
  }, [api, state.selectedTenant]);

  if (error) {
    return <div>Error {error}</div>;
  } else if (item) {
    return <NewForm cpe={item} satInfos={satellites} />;
  } else {
    return <div>Loading</div>;
  }
}

import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed } from "keycloak-js";
import React, { useCallback, useEffect, useState } from "react";
import { createCtx } from "./utils";

export type FetchSignature = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => Promise<Response>;

export interface KeycloakToken extends KeycloakTokenParsed {
  preferred_username: string;
  name: string;
}

export type AuthContextType = {
  isLoaded: boolean;
  isAuthenticated: boolean;
  logout: () => void;
  login: (idp?: string) => void;
  updateUser: (force: boolean) => void;
  register: () => void;
  myAccount: () => void;
  fetch: FetchSignature;
  hasRoles: (roles?: (string | string[])[]) => boolean;
  username?: string;
  name?: string;
  userRoles: string[];
  myAccountUrl: () => string;
  token: string | undefined;
};

type Props = {
  children: React.ReactNode;
};

const [useAuth, CtxProvider] = createCtx<AuthContextType>();

const AuthProvider = ({ children }: Props) => {
  const { keycloak, initialized } = useKeycloak();
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>();
  const [name, setName] = useState<string>();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    console.log("State", !!keycloak, !!initialized);
    if (!initialized) {
      setLoaded(false);
      setUsername(undefined);
      setName(undefined);
      setToken(undefined);
    } else {
      if (keycloak) {
        setLoaded(initialized);
        let token = keycloak.tokenParsed as KeycloakToken;
        setUsername(token?.preferred_username);
        setName(token?.name);
        setToken(keycloak.token);
        if (keycloak && initialized) {
          keycloak.onTokenExpired = () => {
            console.log("Token expired");
            keycloak.updateToken(600);
          };
          keycloak.onAuthRefreshSuccess = () => {
            console.log("Refresh OK");
            setToken(keycloak.token);
          };
          keycloak.onAuthRefreshError = () => {
            console.log("Refresh ERROR");
            setToken(undefined);
          };
        }
      }
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [keycloak, initialized, keycloak.token]);

  useEffect(() => {
    console.log("TokenParsed", keycloak.tokenParsed);
    if (keycloak?.tokenParsed) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [keycloak, keycloak?.tokenParsed]);

  const updateUser = useCallback(
    async (force: boolean = false) => {
      const refreshed = await keycloak.updateToken(force ? 300 : 30);
      if (refreshed) {
        console.log("Token refreshed", keycloak.tokenParsed?.realm_access);
        if (
          JSON.stringify(userRoles) !==
          JSON.stringify(keycloak.tokenParsed?.realm_access?.roles)
        ) {
          setUserRoles(keycloak.tokenParsed?.realm_access?.roles || []);
        }
      }
      return refreshed;
    },
    [keycloak, userRoles]
  );
  /*
  useEffect(() => {
    if (token) {
      console.log("Updating token");
      updateUser();
    }
  }, [token, updateUser]);
  */

  /*
  useEffect(() => {
    setInterval(updateUserCallback, 30000);
  }, [updateUserCallback]);
  */

  const fetchWithAuth = async (
    input: RequestInfo,
    init?: RequestInit | undefined
  ) => {
    await updateUser(false);
    if (keycloak.token) {
      if (init) {
        init.headers = {
          Authorization: `Bearer ${keycloak.token}`,
          // "Content-type": "application/json",
          ...init.headers,
        };
      } else {
        init = {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            // "Content-type": "application/json",
          },
        };
      }
    }
    return fetch(input, init);
  };

  const hasRoles = (roles?: (string | string[])[]) => {
    if (!roles) {
      console.debug("No roles");
      return true;
    }
    if (!isAuthenticated) {
      console.debug("No auth");
      return false;
    }

    let res = roles.some((role) => {
      if (typeof role == "string") {
        if (keycloak.hasRealmRole(role)) {
          return true;
        }
        return false;
      } else {
        let retval = true;
        role.forEach((r) => {
          if (!keycloak.hasRealmRole(r)) {
            retval = false;
          }
        });
        return retval;
      }
    });

    console.debug("Res", res);
    return res;
  };

  const myAccountUrl = () => {
    return keycloak.createAccountUrl();
  };
  /*
  const token = () => {
    if (isAuthenticated) {
      return keycloak.token;
    } else return undefined;
  };
  */

  return (
    <CtxProvider
      value={{
        isLoaded,
        isAuthenticated,
        logout: keycloak.logout,
        login: (idp?: string) => keycloak.login({ idpHint: idp }),
        updateUser,
        register: keycloak.register,
        myAccount: keycloak.accountManagement,
        hasRoles,
        username,
        name,
        fetch: fetchWithAuth,
        userRoles,
        myAccountUrl,
        token,
      }}
    >
      {isLoaded ? children : <div>Loading...</div>}
    </CtxProvider>
  );
};

export { useAuth, AuthProvider };

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

interface Props {
  to: string;
  primary: string;
  icon?: React.ReactElement;
}

export default function NavButton({ to, primary, icon }: Props) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, "to">>(
        function NLink(itemProps, ref) {
          return (
            <NavLink
              className={({ isActive }) => (isActive ? "red" : "blue")}
              to={to}
              ref={ref}
              {...itemProps}
              role={undefined}
            />
          );
        }
      ),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

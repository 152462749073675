import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import TenantMenu from "../../components/Layout/TenantMenu";

export default function Home() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <TenantMenu />
      </Box>
      <Box sx={{ flexGrow: 1, px: 2, py: 4 }}>
        <Outlet />
      </Box>
    </div>
  );
}

import { Box, TextField } from "@mui/material";
import { Field, Form, FormikProps } from "formik";
import { Autocomplete } from "formik-mui";
import SubmitCancel from "../../../components/Forms/SubmitCancel";
import { SatelliteInformation } from "../../../openapi";

export interface Values {
  selected: SatelliteInformation;
}

interface Extra {
  options: SatelliteInformation[];
}

export const SatelliteSelectForm = (props: Extra & FormikProps<Values>) => {
  const { isSubmitting, options } = props;

  return (
    <Form>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Field
          name="selected"
          component={Autocomplete}
          options={options}
          getOptionLabel={(o: SatelliteInformation) => o.name}
          isOptionEqualToValue={(
            o: SatelliteInformation,
            v: SatelliteInformation
          ) => o.id === v.id}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Satelite"
              placeholder="Selección de Satelite"
            />
          )}
        />
      </Box>
      <SubmitCancel isSubmitting={isSubmitting} />
    </Form>
  );
};

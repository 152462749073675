/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Polarization,
    PolarizationFromJSON,
    PolarizationFromJSONTyped,
    PolarizationToJSON,
} from './Polarization';

/**
 * 
 * @export
 * @interface UpdateCpeDto
 */
export interface UpdateCpeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCpeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCpeDto
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCpeDto
     */
    tenantSatId?: string;
    /**
     * 
     * @type {Polarization}
     * @memberof UpdateCpeDto
     */
    polarization?: Polarization;
    /**
     * 
     * @type {string}
     * @memberof UpdateCpeDto
     */
    serial?: string;
}

export function UpdateCpeDtoFromJSON(json: any): UpdateCpeDto {
    return UpdateCpeDtoFromJSONTyped(json, false);
}

export function UpdateCpeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCpeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'tenantSatId': !exists(json, 'tenantSatId') ? undefined : json['tenantSatId'],
        'polarization': !exists(json, 'polarization') ? undefined : PolarizationFromJSON(json['polarization']),
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
    };
}

export function UpdateCpeDtoToJSON(value?: UpdateCpeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tenantId': value.tenantId,
        'tenantSatId': value.tenantSatId,
        'polarization': PolarizationToJSON(value.polarization),
        'serial': value.serial,
    };
}


import { withFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../providers/ApiProvider";
import OrgForm, { OrgForForm, OrgFormProps } from "./OrgForm";

export default function OrgsNew() {
  const api = useApi();
  const navigate = useNavigate();
  const NewForm = withFormik<{ tenant: OrgForForm }, OrgFormProps>({
    mapPropsToValues: (props) => {
      return {
        name: props.tenant.name,
      };
    },
    handleSubmit: async (values, actions) => {
      console.log(values);

      try {
        const res = await api.tenants.tenantControllerCreate({
          createTenantDto: values,
        });
        console.log("RES", res);
        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(OrgForm);

  return <NewForm tenant={{ name: "" }} />;
}

import React, { useState } from "react";
import UserIcon from "@mui/icons-material/Person";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
export const UserDropdown = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const auth = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <UserIcon />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={() => auth.myAccount()}>Mi cuenta</MenuItem>
        <Divider />
        <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
      </Menu>
    </>
  );
};

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";

export default function SatelliteHome() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Typography variant="h4">Satelites</Typography>
      <Box sx={{ flexGrow: 1, px: 2, py: 4 }}>
        <Outlet />
      </Box>
    </div>
  );
}

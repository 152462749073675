import { withFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../providers/ApiProvider";
import Form, { FormProps, FormValues } from "./SatForm";

export default function SatNew() {
  const api = useApi();
  const navigate = useNavigate();
  const NewForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        name: props.sat.name,
        position: Math.abs(props.sat.position),
        east: props.sat.position > 0,
      };
    },
    handleSubmit: async (values, actions) => {
      console.log(values);

      try {
        values.position = values.position * (values.east ? 1 : -1);
        await api.satellites.satelliteInformationControllerCreate({
          createSatelliteInformationDto: values,
        });
        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(Form);

  return <NewForm sat={{ name: "", position: 0 }} />;
}

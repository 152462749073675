import Keycloak from "keycloak-js";
console.log("NODE_ENV", process.env.NODE_ENV);
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloak =
    process.env.NODE_ENV === "development" ?
    new Keycloak({
        url: "http://localhost:8080",
        realm: "satelital",
        clientId: "test-client",
    }) :
    new Keycloak("/keycloak.json");

export default keycloak;
import { Box } from "@mui/system";
import { Field, Form, FormikProps } from "formik";
import { Switch, TextField } from "formik-mui";
import SubmitCancel from "../../../components/Forms/SubmitCancel";
import { SatelliteInformation } from "../../../openapi";

export interface FormProps {
  sat: Omit<SatelliteInformation, "id" | "shortId">
}

export interface FormValues
  extends Omit<SatelliteInformation, "id" | "shortId"> {
  east: boolean;
}

export default function ExtForm({
  touched,
  errors,
  isSubmitting,
  submitForm,
}: FormikProps<FormValues>) {
  return (
    <Form>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Field
          autoFocus
          component={TextField}
          name="name"
          label="Nombre"
          variant="standard"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Field
            component={TextField}
            type="number"
            name="position"
            label="Posición Orbital"
            variant="standard"
            style={{ flex: 1 }}
          />
          <div>Oeste</div>
          <Field
            component={Switch}
            type="checkbox"
            name="east"
            label="Oeste/Este"
          />
          <div>Este</div>
        </Box>
      </Box>
      <SubmitCancel isSubmitting={isSubmitting} />
    </Form>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    lastSatUpdate: Date;
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'lastSatUpdate': (new Date(json['lastSatUpdate'])),
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'lastSatUpdate': (value.lastSatUpdate.toISOString()),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanResponse,
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    Cpe,
    CpeFromJSON,
    CpeToJSON,
    CreateCpeDto,
    CreateCpeDtoFromJSON,
    CreateCpeDtoToJSON,
    UpdateCpeDto,
    UpdateCpeDtoFromJSON,
    UpdateCpeDtoToJSON,
} from '../models';

export interface CpeControllerCreateRequest {
    createCpeDto: CreateCpeDto;
}

export interface CpeControllerFindAllRequest {
    tenantId: string;
}

export interface CpeControllerFindOneRequest {
    id: string;
}

export interface CpeControllerRemoveRequest {
    id: string;
}

export interface CpeControllerUpdateRequest {
    id: string;
    updateCpeDto: UpdateCpeDto;
}

/**
 * 
 */
export class CpeApi extends runtime.BaseAPI {

    /**
     */
    async cpeControllerCreateRaw(requestParameters: CpeControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.createCpeDto === null || requestParameters.createCpeDto === undefined) {
            throw new runtime.RequiredError('createCpeDto','Required parameter requestParameters.createCpeDto was null or undefined when calling cpeControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cpe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCpeDtoToJSON(requestParameters.createCpeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async cpeControllerCreate(requestParameters: CpeControllerCreateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.cpeControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cpeControllerFindAllRaw(requestParameters: CpeControllerFindAllRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Cpe>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling cpeControllerFindAll.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cpe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CpeFromJSON));
    }

    /**
     */
    async cpeControllerFindAll(requestParameters: CpeControllerFindAllRequest, initOverrides?: RequestInit): Promise<Array<Cpe>> {
        const response = await this.cpeControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cpeControllerFindOneRaw(requestParameters: CpeControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Cpe>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cpeControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cpe/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CpeFromJSON(jsonValue));
    }

    /**
     */
    async cpeControllerFindOne(requestParameters: CpeControllerFindOneRequest, initOverrides?: RequestInit): Promise<Cpe> {
        const response = await this.cpeControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cpeControllerRemoveRaw(requestParameters: CpeControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cpeControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cpe/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cpeControllerRemove(requestParameters: CpeControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cpeControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async cpeControllerUpdateRaw(requestParameters: CpeControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cpeControllerUpdate.');
        }

        if (requestParameters.updateCpeDto === null || requestParameters.updateCpeDto === undefined) {
            throw new runtime.RequiredError('updateCpeDto','Required parameter requestParameters.updateCpeDto was null or undefined when calling cpeControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cpe/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCpeDtoToJSON(requestParameters.updateCpeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async cpeControllerUpdate(requestParameters: CpeControllerUpdateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.cpeControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/Layout/Layout";
import keycloak from "./keycloak";
import { ApiProvider } from "./providers/ApiProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { StateProvider } from "./providers/StateProvider";
import RootRouter from "./Router";

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
    >
      <AuthProvider>
        <ApiProvider>
          <BrowserRouter>
            <StateProvider>
              <Layout>
                <RootRouter />
              </Layout>
            </StateProvider>
          </BrowserRouter>
        </ApiProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  );
}

export default App;

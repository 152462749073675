/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanResponse,
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    CreateTenantDto,
    CreateTenantDtoFromJSON,
    CreateTenantDtoToJSON,
    SetSelectedSatellitesDTO,
    SetSelectedSatellitesDTOFromJSON,
    SetSelectedSatellitesDTOToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    TenantSat,
    TenantSatFromJSON,
    TenantSatToJSON,
    UpdateTenantDto,
    UpdateTenantDtoFromJSON,
    UpdateTenantDtoToJSON,
} from '../models';

export interface TenantControllerAddSatelliteRequest {
    id: string;
    satelliteInformationId: string;
}

export interface TenantControllerCreateRequest {
    createTenantDto: CreateTenantDto;
}

export interface TenantControllerDeleteSatelliteRequest {
    id: string;
    tenantSatId: string;
}

export interface TenantControllerFindOneRequest {
    id: string;
}

export interface TenantControllerGetSatellitesRequest {
    id: string;
}

export interface TenantControllerPutSattelitesRequest {
    id: string;
    setSelectedSatellitesDTO: SetSelectedSatellitesDTO;
}

export interface TenantControllerRemoveRequest {
    id: string;
}

export interface TenantControllerUpdateRequest {
    id: string;
    updateTenantDto: UpdateTenantDto;
}

/**
 * 
 */
export class TenantApi extends runtime.BaseAPI {

    /**
     */
    async tenantControllerAddSatelliteRaw(requestParameters: TenantControllerAddSatelliteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerAddSatellite.');
        }

        if (requestParameters.satelliteInformationId === null || requestParameters.satelliteInformationId === undefined) {
            throw new runtime.RequiredError('satelliteInformationId','Required parameter requestParameters.satelliteInformationId was null or undefined when calling tenantControllerAddSatellite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}/satellites/add/{satelliteInformationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"satelliteInformationId"}}`, encodeURIComponent(String(requestParameters.satelliteInformationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tenantControllerAddSatellite(requestParameters: TenantControllerAddSatelliteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tenantControllerAddSatelliteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tenantControllerCreateRaw(requestParameters: TenantControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.createTenantDto === null || requestParameters.createTenantDto === undefined) {
            throw new runtime.RequiredError('createTenantDto','Required parameter requestParameters.createTenantDto was null or undefined when calling tenantControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTenantDtoToJSON(requestParameters.createTenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async tenantControllerCreate(requestParameters: TenantControllerCreateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.tenantControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantControllerDeleteSatelliteRaw(requestParameters: TenantControllerDeleteSatelliteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerDeleteSatellite.');
        }

        if (requestParameters.tenantSatId === null || requestParameters.tenantSatId === undefined) {
            throw new runtime.RequiredError('tenantSatId','Required parameter requestParameters.tenantSatId was null or undefined when calling tenantControllerDeleteSatellite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}/satellites/add/{tenantSatId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"tenantSatId"}}`, encodeURIComponent(String(requestParameters.tenantSatId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tenantControllerDeleteSatellite(requestParameters: TenantControllerDeleteSatelliteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tenantControllerDeleteSatelliteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tenantControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Tenant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantFromJSON));
    }

    /**
     */
    async tenantControllerFindAll(initOverrides?: RequestInit): Promise<Array<Tenant>> {
        const response = await this.tenantControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantControllerFindOneRaw(requestParameters: TenantControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tenant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     */
    async tenantControllerFindOne(requestParameters: TenantControllerFindOneRequest, initOverrides?: RequestInit): Promise<Tenant> {
        const response = await this.tenantControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantControllerGetSatellitesRaw(requestParameters: TenantControllerGetSatellitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TenantSat>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerGetSatellites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}/satellites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantSatFromJSON));
    }

    /**
     */
    async tenantControllerGetSatellites(requestParameters: TenantControllerGetSatellitesRequest, initOverrides?: RequestInit): Promise<Array<TenantSat>> {
        const response = await this.tenantControllerGetSatellitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantControllerPutSattelitesRaw(requestParameters: TenantControllerPutSattelitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerPutSattelites.');
        }

        if (requestParameters.setSelectedSatellitesDTO === null || requestParameters.setSelectedSatellitesDTO === undefined) {
            throw new runtime.RequiredError('setSelectedSatellitesDTO','Required parameter requestParameters.setSelectedSatellitesDTO was null or undefined when calling tenantControllerPutSattelites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}/satellites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetSelectedSatellitesDTOToJSON(requestParameters.setSelectedSatellitesDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tenantControllerPutSattelites(requestParameters: TenantControllerPutSattelitesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tenantControllerPutSattelitesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tenantControllerRemoveRaw(requestParameters: TenantControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async tenantControllerRemove(requestParameters: TenantControllerRemoveRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.tenantControllerRemoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantControllerUpdateRaw(requestParameters: TenantControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tenantControllerUpdate.');
        }

        if (requestParameters.updateTenantDto === null || requestParameters.updateTenantDto === undefined) {
            throw new runtime.RequiredError('updateTenantDto','Required parameter requestParameters.updateTenantDto was null or undefined when calling tenantControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTenantDtoToJSON(requestParameters.updateTenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async tenantControllerUpdate(requestParameters: TenantControllerUpdateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.tenantControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

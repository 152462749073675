/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanResponse,
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    CreateSatelliteInformationDto,
    CreateSatelliteInformationDtoFromJSON,
    CreateSatelliteInformationDtoToJSON,
    SatelliteInformation,
    SatelliteInformationFromJSON,
    SatelliteInformationToJSON,
    UpdateSatelliteInformationDto,
    UpdateSatelliteInformationDtoFromJSON,
    UpdateSatelliteInformationDtoToJSON,
} from '../models';

export interface SatelliteInformationControllerCreateRequest {
    createSatelliteInformationDto: CreateSatelliteInformationDto;
}

export interface SatelliteInformationControllerFindOneRequest {
    id: string;
}

export interface SatelliteInformationControllerRemoveRequest {
    id: string;
}

export interface SatelliteInformationControllerUpdateRequest {
    id: string;
    updateSatelliteInformationDto: UpdateSatelliteInformationDto;
}

/**
 * 
 */
export class SatelliteInformationApi extends runtime.BaseAPI {

    /**
     */
    async satelliteInformationControllerCreateRaw(requestParameters: SatelliteInformationControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.createSatelliteInformationDto === null || requestParameters.createSatelliteInformationDto === undefined) {
            throw new runtime.RequiredError('createSatelliteInformationDto','Required parameter requestParameters.createSatelliteInformationDto was null or undefined when calling satelliteInformationControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/satellite-information`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSatelliteInformationDtoToJSON(requestParameters.createSatelliteInformationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async satelliteInformationControllerCreate(requestParameters: SatelliteInformationControllerCreateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.satelliteInformationControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async satelliteInformationControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SatelliteInformation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/satellite-information`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SatelliteInformationFromJSON));
    }

    /**
     */
    async satelliteInformationControllerFindAll(initOverrides?: RequestInit): Promise<Array<SatelliteInformation>> {
        const response = await this.satelliteInformationControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async satelliteInformationControllerFindOneRaw(requestParameters: SatelliteInformationControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SatelliteInformation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling satelliteInformationControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/satellite-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SatelliteInformationFromJSON(jsonValue));
    }

    /**
     */
    async satelliteInformationControllerFindOne(requestParameters: SatelliteInformationControllerFindOneRequest, initOverrides?: RequestInit): Promise<SatelliteInformation> {
        const response = await this.satelliteInformationControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async satelliteInformationControllerRemoveRaw(requestParameters: SatelliteInformationControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling satelliteInformationControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/satellite-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async satelliteInformationControllerRemove(requestParameters: SatelliteInformationControllerRemoveRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.satelliteInformationControllerRemoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async satelliteInformationControllerUpdateRaw(requestParameters: SatelliteInformationControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling satelliteInformationControllerUpdate.');
        }

        if (requestParameters.updateSatelliteInformationDto === null || requestParameters.updateSatelliteInformationDto === undefined) {
            throw new runtime.RequiredError('updateSatelliteInformationDto','Required parameter requestParameters.updateSatelliteInformationDto was null or undefined when calling satelliteInformationControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/satellite-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSatelliteInformationDtoToJSON(requestParameters.updateSatelliteInformationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async satelliteInformationControllerUpdate(requestParameters: SatelliteInformationControllerUpdateRequest, initOverrides?: RequestInit): Promise<BooleanResponse> {
        const response = await this.satelliteInformationControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

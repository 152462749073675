/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SatelliteInformation
 */
export interface SatelliteInformation {
    /**
     * 
     * @type {string}
     * @memberof SatelliteInformation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SatelliteInformation
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SatelliteInformation
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof SatelliteInformation
     */
    shortId: number;
}

export function SatelliteInformationFromJSON(json: any): SatelliteInformation {
    return SatelliteInformationFromJSONTyped(json, false);
}

export function SatelliteInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SatelliteInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'position': json['position'],
        'shortId': json['shortId'],
    };
}

export function SatelliteInformationToJSON(value?: SatelliteInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'position': value.position,
        'shortId': value.shortId,
    };
}


import React, { useEffect, useState } from "react";
import { Tenant } from "../openapi";
import { useApi } from "./ApiProvider";
import { createCtx } from "./utils";

export type ApiContextType = {
  tenants: Tenant[];
  selectedTenant?: Tenant;
  setSelectedTenant: (t: Tenant) => void;
};

type Props = {
  children: React.ReactNode;
};

const [useAppState, CtxProvider] = createCtx<ApiContextType>();

const StateProvider = ({ children }: Props) => {
  const api = useApi();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>();

  useEffect(() => {
    api.tenants.tenantControllerFindAll().then((tenants) => {
      setTenants(tenants);
      setSelectedTenant(tenants[0]);
    });
  }, [api]);

  return (
    <CtxProvider
      value={{
        tenants,
        selectedTenant,
        setSelectedTenant,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export { useAppState, StateProvider };

import { Add, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridApi,
  GridEnrichedColDef,
  GridRowParams,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TenantSat } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import { useAppState } from "../../../providers/StateProvider";

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
}

function EditToolbar(props: EditToolbarProps) {
  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("new");
  };
  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleAddClick}>
        Agregar
      </Button>
    </GridToolbarContainer>
  );
}

export default function SatelliteList() {
  const apiRef = useGridApiRef();
  const api = useApi();
  const state = useAppState();
  const [data, setData] = useState<TenantSat[]>([]);

  const loadData = useCallback(() => {
    if (state.selectedTenant) {
      api.tenants
        .tenantControllerGetSatellites({
          id: state.selectedTenant.id,
        })
        .then((list) => {
          setData(list);
        });
    }
  }, [setData, api, state]);

  const handleDelete = async (id: string) => {
    console.log("id", id);

    try {
      await api.tenants.tenantControllerDeleteSatellite({
        id: state.selectedTenant!.id,
        tenantSatId: id,
      });
    } catch {
      alert("No se pudo eliminar el registro.");
    }
    loadData();
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: "name",
      valueGetter: (p) => p.row.satelliteInformation.name,
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "usedBy",
      valueGetter: (p) => p.row.cpes.length,
      headerName: "Usuado en...",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Operaciones",

      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          onClick={(v) => handleDelete(params.id.toString())}
          disabled={params.row.cpes.length > 0}
          color="error"
          label="Borrar"
          icon={<Delete />}
        />,
      ],
    },
  ];

  useEffect(() => {
    loadData();
  }, [api, loadData]);

  return (
    <DataGrid
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 20, 50, 10]}
      rows={data}
      components={{
        Toolbar: EditToolbar,
      }}
      componentsProps={{
        toolbar: { apiRef },
      }}
    />
  );
}

import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SatelliteInformation } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import OrgForm, { FormProps, FormValues } from "./SatForm";

export default function Edit() {
  const params = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [item, setItem] = useState<SatelliteInformation>();
  const [id, setId] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (id) {
      api.satellites
        .satelliteInformationControllerFindOne({
          id: id,
        })
        .then((t) => {
          setItem(t);
        })
        .catch((r) => {
          setError(r.status);
        });
    }
  }, [id, api]);

  useEffect(() => {
    setId(params.id);
  }, [params.id]);

  const NewForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        name: props.sat.name,
        position: Math.abs(props.sat.position),
        east: props.sat.position > 0,
      };
    },
    handleSubmit: async (values, actions) => {
      try {
        values.position = values.position * (values.east ? 1 : -1);
        let { east, ...vals } = values;
        await api.satellites.satelliteInformationControllerUpdate({
          id: params.id!,
          updateSatelliteInformationDto: vals,
        });
        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(OrgForm);

  if (error) {
    return <div>Error {error}</div>;
  } else if (item) {
    return <NewForm sat={item} />;
  } else {
    return <div>Loading</div>;
  }
}

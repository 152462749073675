import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  isSubmitting: boolean;
  handleCancel?: () => void;
}

export default function SubmitCancel({ isSubmitting, handleCancel }: Props) {
  const navigate = useNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Divider sx={{ marginY: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          p: 2,
          gap: 2,
        }}
      >
        <Button variant="contained" disabled={isSubmitting} type="submit">
          Guardar
        </Button>
        <Button variant="text" disabled={isSubmitting} onClick={onCancel}>
          Cancelar
        </Button>
      </Box>
    </>
  );
}

import { Add, Delete, Edit, Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridApi,
  GridEnrichedColDef,
  GridRowParams,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cpe } from "../../../openapi/models/Cpe";
import { useApi } from "../../../providers/ApiProvider";
import { useAppState } from "../../../providers/StateProvider";

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
  reloadClick?: () => void;
}

function EditToolbar(props: EditToolbarProps) {
  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("new");
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleAddClick}>
        Agregar
      </Button>
      <Button
        style={{ marginLeft: "auto" }}
        color="secondary"
        startIcon={<Replay />}
        onClick={() => props.reloadClick && props.reloadClick()}
      ></Button>
    </GridToolbarContainer>
  );
}

export default function CpeList() {
  const apiRef = useGridApiRef();
  const api = useApi();
  const state = useAppState();
  const [data, setData] = useState<Cpe[]>([]);
  const navigate = useNavigate();

  const loadData = useCallback(() => {
    if (state.selectedTenant) {
      api.cpes
        .cpeControllerFindAll({
          tenantId: state.selectedTenant.id,
        })
        .then((list) => {
          setData(list);
        });
    }
  }, [setData, api, state]);

  const handleDelete = async (id: string) => {
    await api.cpes.cpeControllerRemove({
      id,
    });
    loadData();
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "lastReport",
      headerName: "Visto el...",
      flex: 1,
    },
    {
      field: "satName",
      headerName: "Satelite",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Operaciones",

      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          onClick={(v) => handleDelete(params.id.toString())}
          color="error"
          label="Borrar"
          icon={<Delete />}
        />,
        <GridActionsCellItem
          onClick={(v) => navigate(`${params.id}/edit`)}
          label="Modificar"
          icon={<Edit />}
        />,
      ],
    },
  ];

  useEffect(() => {
    loadData();
  }, [api, loadData]);

  return (
    <DataGrid
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 20, 50, 10]}
      rows={data}
      components={{
        Toolbar: EditToolbar,
      }}
      componentsProps={{
        toolbar: { apiRef, reloadClick: () => loadData() },
      }}
    />
  );
}

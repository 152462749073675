/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cpe,
    CpeFromJSON,
    CpeFromJSONTyped,
    CpeToJSON,
} from './Cpe';
import {
    SatelliteInformation,
    SatelliteInformationFromJSON,
    SatelliteInformationFromJSONTyped,
    SatelliteInformationToJSON,
} from './SatelliteInformation';

/**
 * 
 * @export
 * @interface TenantSat
 */
export interface TenantSat {
    /**
     * 
     * @type {string}
     * @memberof TenantSat
     */
    id: string;
    /**
     * 
     * @type {SatelliteInformation}
     * @memberof TenantSat
     */
    satelliteInformation: SatelliteInformation;
    /**
     * 
     * @type {Array<Cpe>}
     * @memberof TenantSat
     */
    cpes: Array<Cpe>;
}

export function TenantSatFromJSON(json: any): TenantSat {
    return TenantSatFromJSONTyped(json, false);
}

export function TenantSatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantSat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'satelliteInformation': SatelliteInformationFromJSON(json['satelliteInformation']),
        'cpes': ((json['cpes'] as Array<any>).map(CpeFromJSON)),
    };
}

export function TenantSatToJSON(value?: TenantSat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'satelliteInformation': SatelliteInformationToJSON(value.satelliteInformation),
        'cpes': ((value.cpes as Array<any>).map(CpeToJSON)),
    };
}


import { ExpandMore } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Tenant } from "../../openapi";
import { useAppState } from "../../providers/StateProvider";

export default function TenantMenu() {
  const state = useAppState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTenantClick = (t: Tenant) => {
    state.setSelectedTenant(t);
    setAnchorEl(null);
  };

  return (
    <>
      <List>
        <ListItem button onClick={handleOpenClick}>
          <ListItemText primary={state.selectedTenant?.name}></ListItemText>
          <ListItemIcon>
            <ExpandMore />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ minWidth: 200 }}
      >
        {state.tenants.map((t) => (
          <MenuItem
            key={t.id}
            onClick={() => handleTenantClick(t)}
            selected={t.id === state.selectedTenant?.id}
            sx={{ minWidth: 200 }}
          >
            {t.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tenant } from "../../../openapi";
import { useApi } from "../../../providers/ApiProvider";
import OrgForm, { OrgForForm, OrgFormProps } from "./OrgForm";

export default function OrgsEdit() {
  const params = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [tenant, setTenant] = useState<Tenant>();
  const [tenantId, setTenantId] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (tenantId) {
      api.tenants
        .tenantControllerFindOne({
          id: tenantId,
        })
        .then((t) => {
          setTenant(t);
        })
        .catch((r) => {
          setError(r.status);
        });
    }
  }, [tenantId, api]);

  useEffect(() => {
    setTenantId(params.id);
  }, [params.id]);

  const NewForm = withFormik<{ tenant: OrgForForm }, OrgFormProps>({
    mapPropsToValues: (props) => {
      return {
        name: props.tenant.name,
      };
    },
    handleSubmit: async (values, actions) => {
      console.log(values);

      try {
        const res = await api.tenants.tenantControllerUpdate({
          id: params.id!,
          updateTenantDto: values,
        });
        console.log("RES", res);
        navigate(-1);
      } catch (e: any) {
        const res = await e.json();
        actions.setErrors(res.fieldErrors);
      } finally {
        actions.setSubmitting(false);
      }
    },
  })(OrgForm);

  if (error) {
    return <div>Error {error}</div>;
  } else if (tenant) {
    return <NewForm tenant={tenant} />;
  } else {
    return <div>Loading</div>;
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Control Plane
 * Control Plane Docs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BooleanResponse
 */
export interface BooleanResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanResponse
     */
    result: boolean;
    /**
     * 
     * @type {object}
     * @memberof BooleanResponse
     */
    errors: object;
}

export function BooleanResponseFromJSON(json: any): BooleanResponse {
    return BooleanResponseFromJSONTyped(json, false);
}

export function BooleanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': json['result'],
        'errors': json['errors'],
    };
}

export function BooleanResponseToJSON(value?: BooleanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
        'errors': value.errors,
    };
}


import React from "react";
import { Route, Routes } from "react-router-dom";
import { OrgHome } from "./pages/admin/orgs/OrgHome";
import OrgsEdit from "./pages/admin/orgs/OrgsEdit";
import OrgsList from "./pages/admin/orgs/OrgsList";
import OrgsNew from "./pages/admin/orgs/OrgsNew";
import OrgsView from "./pages/admin/orgs/OrgsView";
import SatEdit from "./pages/admin/sats/SatEdit";
import { SatHome } from "./pages/admin/sats/SatHome";
import SatList from "./pages/admin/sats/SatList";
import SatNew from "./pages/admin/sats/SatNew";
import { Home } from "./pages/Home";
import CpeEdit from "./pages/org/cpes/CpeEdit";
import CpeHome from "./pages/org/cpes/CpeHome";
import CpeList from "./pages/org/cpes/CpeList";
import CpeNew from "./pages/org/cpes/CpeNew";
import AppHome from "./pages/org/Home";
import SatelliteHome from "./pages/org/sats/SatelliteHome";
import SatelliteList from "./pages/org/sats/SatelliteList";
import SatelliteNew from "./pages/org/sats/SatelliteNew";

export default function RootRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="admin">
        <Route path="orgs" element={<OrgHome />}>
          <Route path="new" element={<OrgsNew />}></Route>
          <Route path=":id" element={<OrgsView />}></Route>
          <Route path=":id/edit" element={<OrgsEdit />}></Route>
          <Route index element={<OrgsList />}></Route>
        </Route>
        <Route path="sats" element={<SatHome />}>
          <Route path="new" element={<SatNew />}></Route>
          <Route path=":id/edit" element={<SatEdit />}></Route>
          <Route index element={<SatList />}></Route>
        </Route>
      </Route>
      <Route path="app" element={<AppHome />}>
        <Route path="cpes" element={<CpeHome />}>
          <Route index element={<CpeList />} />
          <Route path="new" element={<CpeNew />} />
          <Route path=":id/edit" element={<CpeEdit />} />
        </Route>
        <Route path="sats" element={<SatelliteHome />}>
          <Route index element={<SatelliteList />} />
          <Route path="new" element={<SatelliteNew />} />
        </Route>
      </Route>
    </Routes>
  );
}

import { Box } from "@mui/system";
import { Field, Form, FormikProps } from "formik";
import { TextField } from "formik-mui";
import SubmitCancel from "../../../components/Forms/SubmitCancel";
import { Tenant } from "../../../openapi";

export interface OrgFormProps {
  name: string;
}

export type OrgForForm = Omit<Tenant, "lastSatUpdate" | "id">;

export default function OrgForm({
  touched,
  errors,
  isSubmitting,
  submitForm,
}: FormikProps<OrgFormProps>) {
  return (
    <Form>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Field
          autoFocus
          component={TextField}
          name="name"
          label="Nombre"
          variant="standard"
        />
      </Box>
      <SubmitCancel isSubmitting={isSubmitting} />
    </Form>
  );
}
